import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const AddCollar = () => {
  const [collarData, setCollarData] = useState({
    collar_Name: '',
    author_ID: localStorage.getItem('authorid'),
  });
  const token = localStorage.getItem("responsetoken");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCollarData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.rollerfashions.com/api/Collar/Add_Collar', collarData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.responseCode === 1) {
        toast.success('Collar added successfully!');
        navigate('/collarlist');
      } else {
        toast.error('Failed to add collar');
      }
    } catch (error) {
      console.error('Error adding collar:', error);
      toast.error('An error occurred while adding collar');
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center coupon-title mb-4">Add Collar</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formCollarName">
              <Form.Label>Collar Name</Form.Label>
              <Form.Control
                type="text"
                name="collar_Name"
                placeholder="Enter collar name"
                value={collarData.collar_Name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Add Collar
            </Button>
          </Form>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default AddCollar;
