// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import { Routes, Route } from 'react-router-dom';
// import Sidenav from './LeftMenu';
// import AdminHeader from "./AdminHeader";
// import Category from './Category';  // Assuming default export from './Category'
// import Subcategory from './Subcategory';  // Assuming default export from './Subcategory'
// import Addproduct from './Addproduct';  // Assuming default export from './Addproduct'
// import Colormanager from './Colormanager';  // Assuming default export from './Colormanager'
// import ProductList from './ProductList';  // Assuming default export from './ProductList'
// import EditProduct from './EditProduct';  // Assuming default export from './EditProduct'
// import Addcoupon from './Addcoupon';
// import Dashboardcounts from './Dashboardcounts';
// import Comboproduct from './Comboproduct';
// import Combolist from './Combolist';
// import EditCombo from './Editcombo';
// import AddBanner from './Addbanner';
// import BannerList from './Bannerlist';
// import AddCustomBanner from './Addcustombanner';
// import CustomBannerList from './Custombannerlist';
// import AddChildProduct from './Addchildproduct';
// import ChildProductList from './Childproductlist';
// import AddFabricType from './AddFabricType';
// import FabricTypeList from './FabricTypeList';
// import EditFabricType from './EditFabricType';
// import AddFitType from './AddFitType';
// import FitTypeList from './FitTypeList';
// import EditFitType from './EditFitType';


// function Dashboard() {
//     return (

//         <div className="container-scroller">
//             <AdminHeader />
//             <Container fluid>
//                 <Row>
//                     <Col md={2}>
//                         <Sidenav />
//                     </Col>
//                     <Col md={10}>
//                         <Routes>
//                             <Route exact path="/category" element={<Category />} />
//                             <Route path="/addproduct" element={<Addproduct />} />
//                             <Route path="/ProductList" element={<ProductList />} />
//                             <Route path="/dashboardcounts" element={<Dashboardcounts />} />
//                             <Route path="/colormanager" element={<Colormanager />}/>
//                             <Route path="/EditProduct/" element={<EditProduct />} />
//                             <Route path="/addcoupon" element={<Addcoupon />} />
//                             <Route path="/addcombo" element={<Comboproduct />} />
//                             <Route path="/Combolist" element={<Combolist />} />
//                             <Route path="/editcombo" element={<EditCombo />} />
//                             <Route path="/addbanner" element={<AddBanner />} />
//                             <Route path="/bannerlist" element={<BannerList />} />
//                             <Route path="/custombanner" element={<AddCustomBanner />} />
//                             <Route path="/custombannerlist" element={<CustomBannerList />} />
//                             <Route path="/addchildproduct" element={<AddChildProduct />} />
//                             <Route path="/childproductlist" element={<ChildProductList /> }/>
//                             <Route path="/addfabrictype" element={<AddFabricType /> }/>
//                             <Route path="/fabrictypelist" element={<FabricTypeList />} />
//                             <Route path="/editfabrictype/:fabricId" element={<EditFabricType /> }/>
//                             <Route path="/addfittype" element={<AddFitType />} />
//                             <Route path="/fittypelist" element={<FitTypeList />} />
//                             <Route path="/editfittype/:fitId" element={<EditFitType />} />
//                         </Routes>
//                     </Col>
//                 </Row>
//             </Container>

//         </div>
//     );
// }

// export default Dashboard;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';
import Sidenav from './LeftMenu';
import AdminHeader from "./AdminHeader";
// Import other components
import Category from './Category';
import Subcategory from './Subcategory';
import Addproduct from './Addproduct';
import Colormanager from './Colormanager';
import ProductList from './ProductList';
import EditProduct from './EditProduct';
import Addcoupon from './Addcoupon';
import Dashboardcounts from './Dashboardcounts';
import Comboproduct from './Comboproduct';
import Combolist from './Combolist';
import EditCombo from './Editcombo';
import AddBanner from './Addbanner';
import BannerList from './Bannerlist';
import AddCustomBanner from './Addcustombanner';
import CustomBannerList from './Custombannerlist';
import AddChildProduct from './Addchildproduct';
import ChildProductList from './Childproductlist';
import AddFabricType from './AddFabricType';
import FabricTypeList from './FabricTypeList';
import EditFabricType from './EditFabricType';   // Import EditFabricType
import AddFitType from './AddFitType';
import FitTypeList from './FitTypeList';
import EditFitType from './EditFitType';   // Import EditFitType
import AddHSN from './Addhsn';
import HSNList from './Hsnlist';
import EditHSN from './Edithsn';
import AddPattern from './Addpattern';
import PatternList from './Patternlist';
import EditPattern from './Editpattern';
import AddOccasion from './Addoccasion';
import OccasionList from './Occasionlist';
import AddCollar from './Addcollar';
import CollarList from './Collarlist';
import EditOccasion from './Editoccasion';

function Dashboard() {
    return (
        <div className="container-scroller">
            <AdminHeader />
            <Container fluid>
                <Row>
                    <Col md={2}>
                        <Sidenav />
                    </Col>
                    <Col md={10}>
                        <Routes>
                            <Route exact path="/category" element={<Category />} />
                            <Route path="/addproduct" element={<Addproduct />} />
                            <Route path="/ProductList" element={<ProductList />} />
                            <Route path="/dashboardcounts" element={<Dashboardcounts />} />
                            <Route path="/colormanager" element={<Colormanager />} />
                            <Route path="/EditProduct/:id" element={<EditProduct />} />
                            <Route path="/addcoupon" element={<Addcoupon />} />
                            <Route path="/addcombo" element={<Comboproduct />} />
                            <Route path="/Combolist" element={<Combolist />} />
                            <Route path="/editcombo/:id" element={<EditCombo />} />
                            <Route path="/addbanner" element={<AddBanner />} />
                            <Route path="/bannerlist" element={<BannerList />} />
                            <Route path="/custombanner" element={<AddCustomBanner />} />
                            <Route path="/custombannerlist" element={<CustomBannerList />} />
                            <Route path="/addchildproduct" element={<AddChildProduct />} />
                            <Route path="/childproductlist" element={<ChildProductList />} />
                            <Route path="/addfabrictype" element={<AddFabricType />} />
                            <Route path="/fabrictypelist" element={<FabricTypeList />} />
                            {/* Add the edit routes inside the dashboard */}
                            <Route path="/editfabrictype/:fabricId" element={<EditFabricType />} />
                            <Route path="/addfittype" element={<AddFitType />} />
                            <Route path="/fittypelist" element={<FitTypeList />} />
                            <Route path="/editfittype/:fitId" element={<EditFitType />} />
                            <Route path="/addhsn" element={<AddHSN />} />
                            <Route path="/hsnlist" element={<HSNList />} />
                            <Route path="/edithsn/:id" element={<EditHSN />}/>
                            <Route path="/addpattern" element={<AddPattern />}/>
                            <Route path="/patternlist" element={<PatternList />} />
                            <Route path="/editpattern/:id" element={<EditPattern />}/>
                            <Route path="/addoccasion" element={<AddOccasion />}/>
                            <Route path="/occasionlist" element={<OccasionList />}/>
                            <Route path="/editoccasion/:id" element={<EditOccasion />}/>
                            <Route path="/addcollar" element={<AddCollar />} />
                            <Route path="/collarlist" element={<CollarList />}/>
                            <Route path="/editcollar/:id" element={<EditOccasion />}/>
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Dashboard;
