import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import Logo from '../assets/images/Main_logo.png';
import "../assets/css/materialdesignicons.min.css";
import "../assets/css/vendor.bundle.base.css";
import "../assets/css/jquery-jvectormap.css";
import "../assets/css/flag-icon.min.css";
import "../assets/css/owl.carousel.min.css";
import "../assets/css/dashboard.css";
import { useState } from 'react';

function Sidenav() {
    const [isProductManagementOpen, setProductManagementOpen] = useState(false);
    const [isCouponManagementOpen, setCouponManagementOpen] = useState(false);
    const [isComboManagementOpen, setComboManagementOpen] =useState(false);
    const [isBannerManagementOpen, setBannerManagementOpen] =useState(false);
    const [isFabricTypeManagementOpen, setFabricManagementOpen] =useState(false);
    const [isFitTypeManagementOpen, setFitTypeManagementOpen] =useState(false);
    const [isHsnManagementOpen, setHsnManagementOpen] =useState(false);
    const [isPatternManagementOpen, setPatternManagementOpen] =useState(false);
    const [isOccasionManagementOpen, setOccasionManagementOpen] =useState(false);
    const [isCollarManagementOpen, setCollarManagementOpen] =useState(false);
    

    const toggleProductManagement = () => {
        setProductManagementOpen(!isProductManagementOpen);
    };
    const toggleCouponManagement = () => {
        setCouponManagementOpen(!isCouponManagementOpen);
    }
    const toggleComboManagement = () => {
        setComboManagementOpen(!isComboManagementOpen);
    }
    const toggleBannerManagement = () =>{
        setBannerManagementOpen(!isBannerManagementOpen);
    }
    const toggleFabricManagementOpen = () =>{
        setFabricManagementOpen(!isFabricTypeManagementOpen);
    }
    const toggleFitTypeManagementOpen =() =>{
        setFitTypeManagementOpen(!isFitTypeManagementOpen);
    }
    const toggleHsnManagementOpen =() =>{
        setHsnManagementOpen(!isHsnManagementOpen);
    }
    const togglePatternManagementOpen =() =>{
        setPatternManagementOpen(!isPatternManagementOpen);
    }
    const toggleOccasionManagementOpen =() =>{
        setOccasionManagementOpen(!isOccasionManagementOpen);
    }
    const toggleCollarManagementOpen =() =>{
        setCollarManagementOpen(!isCollarManagementOpen);
    }

    return (
        <>
            <AdminHeader />
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                    <a className="sidebar-brand brand-logo" href="/AdminDashboard/dashboardcounts"><Image src={Logo} fluid className="Logo" /></a>
                    <a className="sidebar-brand brand-logo-mini" href="/AdminDashboard/dashboardcounts"><Image src={Logo} fluid className="Logo" /></a>
                </div>
                <ul className="nav">
                    <li className="nav-item profile">
                        <div className="profile-desc">
                            {/* <div className="profile-pic">
                                <div className="count-indicator">
                                    <img className="img-xs rounded-circle" src="assets/images/faces/face15.jpg" alt="" />
                                    <span className="count bg-success"></span>
                                </div>
                                <div className="profile-name">
                                    <h5 className="mb-0 font-weight-normal">Henry Klein</h5>
                                    <span>Gold Member</span>
                                </div>
                            </div> */}
                            {/* <a href="#" id="profile-dropdown" data-toggle="dropdown"><i className="mdi mdi-dots-vertical"></i></a> */}
                            <div className="dropdown-menu dropdown-menu-right sidebar-dropdown preview-list" aria-labelledby="profile-dropdown">
                                <a href="#" className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <div className="preview-icon bg-dark rounded-circle">
                                            <i className="mdi mdi-settings text-primary"></i>
                                        </div>
                                    </div>
                                    <div className="preview-item-content">
                                        <p className="preview-subject ellipsis mb-1 text-small">Account settings</p>
                                    </div>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <div className="preview-icon bg-dark rounded-circle">
                                            <i className="mdi mdi-onepassword text-info"></i>
                                        </div>
                                    </div>
                                    <div className="preview-item-content">
                                        <p className="preview-subject ellipsis mb-1 text-small">Change Password</p>
                                    </div>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <div className="preview-icon bg-dark rounded-circle">
                                            <i className="mdi mdi-calendar-today text-success"></i>
                                        </div>
                                    </div>
                                    <div className="preview-item-content">
                                        <p className="preview-subject ellipsis mb-1 text-small">To-do list</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item nav-category">
                        <span className="nav-link">Navigation</span>
                    </li>
                    <li className="nav-item menu-items">
                        <Link className="nav-link" to="/AdminDashboard/dashboardcounts"> {/* Correct path to Dashboardcounts */}
                            <span className="menu-icon">
                                <i className="mdi mdi-speedometer"></i>
                            </span>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleProductManagement} href="#ui-basic" aria-expanded={isProductManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">Product Management</span>
                            <i className={`menu-arrow ${isProductManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isProductManagementOpen ? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addproduct">Add Product</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/productlist">Product List</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addchildproduct">Add Child Product</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/childproductlist">Child Product List</Link></li>
                                <ul className="nav flex-column sub-menu"></ul>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <Link className="nav-link" to="/AdminDashboard/category">
                            <span className="menu-icon">
                                <i className="mdi mdi-playlist-play"></i>
                            </span>
                            <span className="menu-title">Category Management</span>
                        </Link>
                    </li>
                    <li className="nav-item menu-items">
                        <Link className="nav-link" to="/AdminDashboard/ColorManager">
                            <span className="menu-icon">
                                <i className="mdi mdi-table-large"></i>
                            </span>
                            <span className="menu-title">Color Management</span>
                        </Link>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleCouponManagement} href="#ui-basic" aria-expanded={isCouponManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-file-document-box"></i>
                            </span>
                            <span className="menu-title">Coupon Management</span>
                            <i className={`menu-arrow ${isCouponManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isCouponManagementOpen ? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addcoupon">Add coupon</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleComboManagement} href="#ui-basic" aria-expanded={isComboManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">Combo Management</span>
                            <i className={`menu-arrow ${isComboManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isComboManagementOpen ? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addcombo">Add Combo</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/combolist">Combo List</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" href="pages/charts/chartjs.html">
                            <span className="menu-icon">
                                <i className="mdi mdi-chart-bar"></i>
                            </span>
                            <span className="menu-title">Order Transactions</span>
                        </a>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" href="pages/icons/mdi.html">
                            <span className="menu-icon">
                                <i className="mdi mdi-contacts"></i>
                            </span>
                            <span className="menu-title">Customers List</span>
                        </a>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
                            <span className="menu-icon">
                                <i className="mdi mdi-security"></i>
                            </span>
                            <span className="menu-title">Notifications</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="auth">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/blank-page.html"> Blank Page </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/error-404.html"> 404 </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/error-500.html"> 500 </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/login.html"> Login </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/register.html"> Register </a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" href="">
                            <span className="menu-icon">
                                <i className="mdi mdi-file-document-box"></i>
                            </span>
                            <span className="menu-title">User Management</span>
                        </a>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleBannerManagement} href="#ui-basic" aria-expanded={isBannerManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">Banner Management</span>
                            <i className={`menu-arrow ${isBannerManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isBannerManagementOpen ? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addbanner">Add Banner</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/bannerlist">Banner List</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/custombanner">Add Custom Banner</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/custombannerlist">Custom Banner List</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleFabricManagementOpen} href="#ui-basic" aria-expanded={isFabricTypeManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">Fabric Type Management</span>
                            <i className={`menu-arrow ${isFabricTypeManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isFabricTypeManagementOpen ? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addfabrictype">Add Fabric Type</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/fabrictypelist">Fabric Type List</Link></li>
                                <ul className="nav flex-column sub-menu"></ul>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleFitTypeManagementOpen} href="#ui-basic" aria-expanded={isFitTypeManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">Fit Type Management</span>
                            <i className={`menu-arrow ${isFitTypeManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isFitTypeManagementOpen ? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addfittype">Add FitType </Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/fittypelist">Fit Type List</Link></li>
                                <ul className="nav flex-column sub-menu"></ul>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleHsnManagementOpen} href="#ui-basic" aria-expanded={isHsnManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">HSN Management</span>
                            <i className={`menu-arrow ${isHsnManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isHsnManagementOpen ? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addhsn">Add HSN</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/hsnlist">HSN List</Link></li>
                                <ul className="nav flex-column sub-menu"></ul>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={togglePatternManagementOpen} href="#ui-basic" aria-expanded={isPatternManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">Pattern Management</span>
                            <i className={`menu-arrow ${isPatternManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isPatternManagementOpen ? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addpattern">Add Pattern</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/patternlist">Pattern List</Link></li>
                                <ul className="nav flex-column sub-menu"></ul>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleOccasionManagementOpen} href="#ui-basic" aria-expanded={isOccasionManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">Occasion Management</span>
                            <i className={`menu-arrow ${isOccasionManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isOccasionManagementOpen? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addoccasion">Add Occasion</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/occasionlist">Occasion List</Link></li>
                                <ul className="nav flex-column sub-menu"></ul>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item menu-items">
                        <a className="nav-link" onClick={toggleCollarManagementOpen} href="#ui-basic" aria-expanded={isCollarManagementOpen} aria-controls="ui-basic">
                            <span className="menu-icon">
                                <i className="mdi mdi-laptop"></i>
                            </span>
                            <span className="menu-title">Collar Management</span>
                            <i className={`menu-arrow ${isCollarManagementOpen ? 'rotate-arrow' : ''}`}></i>
                        </a>
                        <div className={`collapse ${isCollarManagementOpen? 'show' : ''}`} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/addcollar">Add Collar</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/AdminDashboard/collarlist">Collar List</Link></li>
                                <ul className="nav flex-column sub-menu"></ul>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Sidenav;
