import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/addhsn.css";

const AddOccasion = () => {
    const [occasionType, setOccasionType] = useState('');
    const authorId = localStorage.getItem('authorid');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!occasionType || !authorId) {
            toast.error('Please fill in all required fields');
            return;
        }

        const occasionData = {
            occasion_type: occasionType,
            author_ID: authorId,
        };

        try {
            const token = localStorage.getItem("responsetoken");
            const response = await axios.post(
                'https://api.rollerfashions.com/api/Occasion/Add_Occasion', 
                occasionData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            if (response.data.responseCode === 1) {
                toast.success('Occasion created successfully!');
                setOccasionType(''); // Reset form field after success
            } else {
                toast.error('Failed to create occasion');
            }
        } catch (error) {
            console.error('Error creating occasion:', error);
            toast.error('An error occurred while creating occasion');
        }
    };

    return (
        <Container fluid className="create-occasion-container">
            <Row className="justify-content-md-center">
                <Col md={6} className="form-container p-4 rounded shadow">
                    <h2 className="text-center mb-4 coupon-title">Create Occasion</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formOccasionType">
                            <Form.Label className="text-white">Occasion Type</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Occasion Type"
                                value={occasionType}
                                onChange={(e) => setOccasionType(e.target.value)}
                                required
                                className="input-field"
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100 btn-submit">
                            Create Occasion
                        </Button>
                    </Form>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
};

export default AddOccasion;
