// import React, { useState, forwardRef, useImperativeHandle } from 'react';
// import { Button } from 'react-bootstrap';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const CSVUpload = forwardRef(({ onUploadSuccess }, ref) => {
//     const [csvFile, setCsvFile] = useState(null);
//     const token = localStorage.getItem('responsetoken'); // Token for authorization

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             console.log('Selected file:', file);
//             setCsvFile(file);
//         }
//     };

//     const handleUpload = async () => {
//         if (!csvFile) {
//             toast.error('No CSV file selected');
//             return;
//         }

//         try {
//             const formData = new FormData();
//             formData.append('files', csvFile); // Append the file to FormData
// debugger;
//             const response = await axios.post(`https://api.rollerfashions.com/api/Product/Bulk_ProductUpload`, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                     'Authorization': `Bearer ${token}`,
//                 },
//             });

//             console.log('API Response:', response.data);

//             if (response.data.responseCode === 1) {
//                 toast.success('Upload successful!');
//                 if (onUploadSuccess) onUploadSuccess();
//                 reset();
//             } else {
//                 toast.error(`Error: ${response.data.responsemessage || 'Unknown error'}`);
//             }
//         } catch (error) {
//             console.error('Error uploading file:', error);
//             if (error.response) {
//                 toast.error(`Error: ${error.response.data.message || 'Error uploading file'}`);
//             } else {
//                 toast.error('Error uploading file');
//             }
//         }
//     };

//     const reset = () => {
//         setCsvFile(null);
//     };

//     useImperativeHandle(ref, () => ({
//         reset,
//     }));

//     return (
//         <div className="csv-upload mt-3">
//         <div style={{ marginBottom: '10px' }}>
//             <input
//                 type="file"
//                 accept=".csv"
//                 onChange={handleFileChange}
//             />
//         </div>
//         <div>
//             <Button onClick={handleUpload} variant="primary">
//                 Upload CSV
//             </Button>
//         </div>
//     </div>
//     );
// });

// export default CSVUpload;











import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CSVUpload = forwardRef(({ onUploadSuccess }, ref) => {
    const [csvFile, setCsvFile] = useState(null);
    const token = localStorage.getItem('responsetoken'); // Token for authorization

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log('Selected file:', file);
            setCsvFile(file);
        }
    };

    const handleUpload = async () => {
        if (!csvFile) {
            toast.error('No CSV file selected');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('files', csvFile); // Ensure 'files' is the correct field name

            const response = await axios.post(
                'https://api.rollerfashions.com/api/Product/Bulk_ProductUpload',
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        // Removed 'Content-Type' because axios handles it automatically for FormData
                    }
                }
            );

            console.log('API Response:', response.data);

            if (response.data.responseCode === 1) {
                toast.success('Upload successful!');
                if (onUploadSuccess) onUploadSuccess();
                reset();
            } else {
                toast.error(`Error: ${response.data.responsemessage || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            if (error.response) {
                toast.error(`Error: ${error.response.data.message || 'Error uploading file'}`);
            } else {
                toast.error('Error uploading file');
            }
        }
    };

    const reset = () => {
        setCsvFile(null);
    };

    useImperativeHandle(ref, () => ({
        reset,
    }));

    return (
        <div className="csv-upload mt-3">
            <div style={{ marginBottom: '10px' }}>
                <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                />
            </div>
            <div>
                <Button onClick={handleUpload} variant="primary">
                    Upload CSV
                </Button>
            </div>
        </div>
    );
});

export default CSVUpload;
