import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash } from 'react-icons/fa';

const CollarList = () => {
  const [collarList, setCollarList] = useState([]);
  const token = localStorage.getItem("responsetoken");

  const fetchCollarList = async () => {
    try {
      const response = await axios.get('https://api.rollerfashions.com/api/Collar/List_Collar', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      setCollarList(response.data || []);
    } catch (error) {
      console.error('Error fetching collar list:', error);
      Swal.fire('Error', 'Failed to fetch collar list', 'error');
    }
  };

  const handleDelete = async (id, author_ID) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will permanently delete the collar!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {
        await axios.post('https://api.rollerfashions.com/api/Collar/Delete_Collar', {
          id: id,
          author_ID: author_ID
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });
        Swal.fire('Deleted!', 'Collar has been deleted.', 'success');
        fetchCollarList(); // Refresh list after deletion
      } catch (error) {
        console.error('Error deleting collar:', error);
        Swal.fire('Error', 'Failed to delete collar', 'error');
      }
    }
  };

  useEffect(() => {
    fetchCollarList();
  }, []);

  return (
    <div>
      <h2 className="coupon-title">Collar List</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Collar Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {collarList.map((collar) => (
            <tr key={collar.id}>
              <td>{collar.collar_Name}</td>
              <td>
                <Link to={`/editcollar/${collar.id}`}>
                  <Button variant="warning" className="me-2"><FaEdit /></Button>
                </Link>
                <Button variant="danger" onClick={() => handleDelete(collar.id, collar.author_ID)}><FaTrash /></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CollarList;
