import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/addhsn.css"
// import "../assets/css/addpattern.css"; // Adjust the path as necessary

const AddPattern = () => {
    const [patternType, setPatternType] = useState('');
    const authorId = localStorage.getItem('authorid'); // Retrieve author ID from local storage

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!patternType || !authorId) {
            toast.error('Please fill in all required fields');
            return;
        }

        const patternData = {
            pattern_type: patternType,
            author_ID: authorId,
        };

        try {
            const token = localStorage.getItem("responsetoken"); // Retrieve the token
            const response = await axios.post(
                'https://api.rollerfashions.com/api/Pattern/Add_Pattern',
                patternData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            if (response.data.responseCode === 1) {
                toast.success('Pattern created successfully!');
                setPatternType(''); // Clear the input field
            } else {
                toast.error('Failed to create pattern');
            }
        } catch (error) {
            console.error('Error creating pattern:', error);
            toast.error('An error occurred while creating pattern');
        }
    };

    return (
        <Container fluid className="create-pattern-container">
            <Row className="justify-content-md-center">
                <Col md={6} className="form-container p-4 rounded shadow">
                    <h2 className="coupon-title">Create Pattern</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formPatternType">
                            <Form.Label className="text-white">Pattern Type</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter pattern type"
                                value={patternType}
                                onChange={(e) => setPatternType(e.target.value)}
                                required
                                className="input-field"
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100 btn-submit">
                            Create Pattern
                        </Button>
                    </Form>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
};

export default AddPattern;
