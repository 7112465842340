import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash } from 'react-icons/fa';

const OccasionList = () => {
  const [occasionList, setOccasionList] = useState([]);
  const token = localStorage.getItem("responsetoken");

  // Fetch the occasion list
  const fetchOccasionList = async () => {
    try {
      const response = await axios.get('https://api.rollerfashions.com/api/Occasion/List_Occasion', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      setOccasionList(response.data || []);
    } catch (error) {
      console.error('Error fetching occasion list:', error);
      Swal.fire('Error', 'Failed to fetch occasion list', 'error');
    }
  };

  // Delete an occasion
  const handleDelete = async (id, author_ID) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will permanently delete the occasion!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {
        await axios.post('https://api.rollerfashions.com/api/Occasion/Delete_Occasion', {
          id: id,
          author_ID: author_ID
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });
        Swal.fire('Deleted!', 'Occasion has been deleted.', 'success');
        fetchOccasionList(); // Refresh list after deletion
      } catch (error) {
        console.error('Error deleting occasion:', error);
        Swal.fire('Error', 'Failed to delete occasion', 'error');
      }
    }
  };

  useEffect(() => {
    fetchOccasionList();
  }, []);

  return (
    <div>
      <h2 className="coupon-title">Occasion List</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Occasion Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {occasionList.map((occasion) => (
            <tr key={occasion.id}>
              <td>{occasion.occasion_type}</td>
              <td>
                <Link to={`/editoccasion/${occasion.id}`}>
                  <Button variant="warning" className="me-2"><FaEdit /></Button>
                </Link>
                <Button variant="danger" onClick={() => handleDelete(occasion.id, occasion.author_ID)}><FaTrash /></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default OccasionList;
