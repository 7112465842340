import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/addhsn.css"

const AddHSN = () => {
    const [hsn, setHsn] = useState('');
    const [percentage, setPercentage] = useState('');
    const authorId = localStorage.getItem('authorid');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!hsn || !percentage || !authorId) {
            toast.error('Please fill in all required fields');
            return;
        }

        const hsnData = {
            hsn: hsn,
            percentage: percentage,
            author_ID: authorId,
        };

        debugger;
        try {
            const token = localStorage.getItem("responsetoken");
            const response = await axios.post(
                'https://api.rollerfashions.com/api/Hsn/Add_Hsn',hsnData ,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            if (response.data.responseCode === 1) {
                toast.success('HSN created successfully!');
                setHsn('');
                setPercentage('');
            } else {
                toast.error('Failed to create HSN');
            }
        } catch (error) {
            console.error('Error creating HSN:', error);
            toast.error('An error occurred while creating HSN');
        }
    };

    return (
        <Container fluid className="create-hsn-container">
            <Row className="justify-content-md-center">
                <Col md={6} className="form-container p-4 rounded shadow">
                    <h2 className="text-center mb-4 text-white">Create HSN</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formHsn">
                            <Form.Label className="text-white">HSN</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter HSN"
                                value={hsn}
                                onChange={(e) => setHsn(e.target.value)}
                                required
                                className="input-field"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPercentage">
                            <Form.Label className="text-white">Percentage</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter percentage"
                                value={percentage}
                                onChange={(e) => setPercentage(e.target.value)}
                                required
                                className="input-field"
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100 btn-submit">
                            Create HSN
                        </Button>
                    </Form>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
};

export default AddHSN;
