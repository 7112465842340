// HSNList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash } from 'react-icons/fa';

const HSNList = () => {
  const [hsnList, setHsnList] = useState([]);
  const token = localStorage.getItem("responsetoken"); // Retrieve the token

  const fetchHsnList = async () => {
    try {
      const response = await axios.get('https://api.rollerfashions.com/api/Hsn/List_Hsn', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      setHsnList(response.data || []);
    } catch (error) {
      console.error('Error fetching HSN list:', error);
      Swal.fire('Error', 'Failed to fetch HSN list', 'error');
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will permanently delete the HSN!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`https://api.rollerfashions.com/api/Hsn/Delete_Hsn?Hsn_ID=${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });
        Swal.fire('Deleted!', 'HSN has been deleted.', 'success');
        fetchHsnList(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting HSN:', error);
        Swal.fire('Error', 'Failed to delete HSN', 'error');
      }
    }
  };

  useEffect(() => {
    fetchHsnList();
  }, []);

  return (
    <div>
      <h2 className="coupon-title">HSN List</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>HSN</th>
            <th>Percentage</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {hsnList.map((hsn) => (
            <tr key={hsn.id}>
              <td>{hsn.hsn}</td>
              <td>{hsn.percentage}</td>
              <td>
                <Link to={`/edithsn/${hsn.id}`}>
                  <Button variant="warning" className="me-2"><FaEdit/></Button>
                </Link>
                <Button variant="danger" onClick={() => handleDelete(hsn.id)}><FaTrash/></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default HSNList;
