// import React, { useState, useEffect } from 'react';
// import { Table, Button, Modal } from 'react-bootstrap';
// import axios from 'axios';
// import { FaEdit, FaTrash } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import "../assets/css/Comboproduct.css";

// function Combolist() {
//     const [products, setProducts] = useState([]);
//     const [take, setTake] = useState(10); // Number of products to take
//     const [skip, setSkip] = useState(0);  // Number of products to skip
//     const [showImageModal, setShowImageModal] = useState(false);
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [showDeleteModal, setShowDeleteModal] = useState(false);
//     const [deleteProductId, setDeleteProductId] = useState(null);

//     const token = localStorage.getItem("responsetoken");
//     const authorid = localStorage.getItem("authorid");


//     useEffect(() => {
//         fetchProducts();
//     }, [take, skip]); // Reload products when take or skip changes

//     const fetchProducts = async () => {
//         try {
//             const url = `https://api.rollerfashions.com/api/Combo_product/List_comboproduct?take=${take}&skip=${skip}`;
//             const response = await axios.get(url, {
//                 headers: {
//                     'Authorization': `Bearer ${token} `,
//                     'Content-Type': 'application/json'
//                 }
//             });
//             if (skip === 0) {
//                 // If skip is 0, reset products with new data
//                 setProducts(response.data);
//             } else {
//                 // If skip is not 0, append new products to existing list
//                 setProducts([...products, ...response.data]);
//             }
//         } catch (error) {
//             console.error("Error fetching products:", error);
//             toast.error("An error occurred while fetching products. Please try again.", {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//             });
//         }
//     };

//     const handleDelete = async () => {
//         if (deleteProductId) {
//             try {
//                 const url = `https://api.rollerfashions.com/api/Combo_product/Delete_comboproduct`;
//                 const obj = {
//                     id: deleteProductId,
//                     author_ID: authorid
//                 };

//                 const response = await axios.post(url, obj, {
//                     headers: {
//                         'Authorization': `Bearer ${token} `,
//                         'Content-Type': 'application/json'
//                     }
//                 });

//                 toast.success(response.data.responsemessage, {
//                     position: "top-right",
//                     autoClose: 3000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                 });

//                 fetchProducts();
//             } catch (error) {
//                 console.error("Error deleting product:", error);
//                 toast.error("An error occurred while deleting the product. Please try again.", {
//                     position: "top-right",
//                     autoClose: 3000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                 });
//             } finally {
//                 setShowDeleteModal(false);
//                 setDeleteProductId(null);
//             }
//         }
//     };

//     const handleShowMore = () => {
//         setSkip(skip + take); // Increment skip to fetch the next set of products
//     };

//     const handleImageClick = (image) => {
//         setSelectedImage(image);
//         setShowImageModal(true);
//     };

//     const handleCloseImageModal = () => {
//         setShowImageModal(false);
//         setSelectedImage(null);
//     };

//     const handleDeleteClick = (productId) => {
//         setDeleteProductId(productId);
//         setShowDeleteModal(true);
//     };

//     const handleCloseDeleteModal = () => {
//         setShowDeleteModal(false);
//         setDeleteProductId(null);
//     };

//     return (
//         <div className="container-fluid combo-list">
//             <h2 className='primary'>Combo's List</h2>
//             <Table hover className='table-dark'>
//                 <thead>
//                     <tr>
//                         <th>Display Image</th>
//                         <th>Name</th>
//                         <th>SKU Number</th>
//                         <th>Price</th>
//                         <th>Category</th>
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {products && products.map(product => (
//                         <tr key={product.id}>
//                             <td>
//                                 <img 
//                                     src={`data:image/jpeg;base64,${product.display_image}`} 
//                                     alt={product.name} 
//                                     className="product-list-image" 
//                                     onClick={() => handleImageClick(product.display_image)} 
//                                     style={{ cursor: 'pointer' }}
//                                 />
//                             </td>
//                             <td>{product.name}</td>
//                             <td>{product.skq_number}</td>
//                             <td>{product.price}</td>
//                             <td>{product.category}</td>
//                             <td>
//                                 <Link to={`/editcombo/${product.id}?comboproduct_ID=${product.comboproduct_ID}`}>
//                                     <FaEdit />
//                                 </Link>
//                                 <Button variant="danger" className="action-button" onClick={() => handleDeleteClick(product.id)}>
//                                     <FaTrash />
//                                 </Button>
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </Table>
//             {products.length > 0 && ( // Display "Show More" button only if there are products
//                 <Button variant="primary" onClick={handleShowMore}>Show More</Button>
//             )}

//             {/* Image Preview Modal */}
//             <Modal show={showImageModal} onHide={handleCloseImageModal}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Image Preview</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {selectedImage && <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Preview" style={{ width: '100%' }} />}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleCloseImageModal}>Close</Button>
//                 </Modal.Footer>
//             </Modal>

//             {/* Delete Confirmation Modal */}
//             <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Confirm Deletion</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
//                     <Button variant="danger" onClick={handleDelete}>Delete</Button>
//                 </Modal.Footer>
//             </Modal>

//             <ToastContainer />
//         </div>
//     );
// }

// export default Combolist;


import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../assets/css/Comboproduct.css";

function Combolist() {
    const [products, setProducts] = useState([]);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteProductId, setDeleteProductId] = useState(null);

    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchProducts();
    }, [take, skip]);

    const fetchProducts = async () => {
        if (!token) {
            toast.error("Authorization token missing. Please login.");
            return;
        }

        setLoading(true);
        try {
            const url = `https://api.rollerfashions.com/api/Combo_product/List_comboproduct?take=${take}&skip=${skip}`;
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log("API console", response.data)
            if (response.data.length < take) {
                setHasMore(false);
            }

            if (skip === 0) {
                setProducts(response.data);
            } else {
                setProducts(prevProducts => [...prevProducts, ...response.data]);
            }
        } catch (error) {
            console.error("Error fetching products:", error);
            toast.error("An error occurred while fetching products. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleShowMore = () => {
        if (hasMore) {
            setSkip(skip + take);
        }
    };

    const handleDelete = async () => {
        if (deleteProductId) {
            setLoading(true);
            try {
                const url = `https://api.rollerfashions.com/api/Combo_product/Delete_comboproduct`;
                const obj = { id: deleteProductId, author_ID: authorid };

                const response = await axios.post(url, obj, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                toast.success(response.data.responsemessage);
                fetchProducts();
            } catch (error) {
                console.error("Error deleting product:", error);
                toast.error("An error occurred while deleting the product. Please try again.");
            } finally {
                setLoading(false);
                setShowDeleteModal(false);
                setDeleteProductId(null);
            }
        }
    };

    return (
        <div className="container-fluid combo-list">
            <h2 className='primary'>Combo's List</h2>
            <Table hover className='table-dark'>
                <thead>
                    <tr>
                        <th>Display Image</th>
                        <th>Name</th>
                        <th>SKU Number</th>
                        <th>Price</th>
                        <th>Category</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.length > 0 ? (
                        products.map(product => (
                            <tr key={product.id}>
                                <td>
                                    <img
                                        src={`${product.display_image}`}
                                        alt={product.name}
                                        className="product-list-image"
                                        onClick={() => setSelectedImage(product.display_image)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </td>
                                <td>{product.name}</td>
                                <td>{product.skq_number}</td>
                                <td>{product.price}</td>
                                <td>{product.category_name}</td>
                                <td>
                                    <Link to={`/editcombo/${product.id}?comboproduct_ID=${product.comboproduct_ID}`}>
                                        <FaEdit />
                                    </Link>
                                    <Button variant="danger" className="action-button" onClick={() => setDeleteProductId(product.id)}>
                                        <FaTrash />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr><td colSpan="6">No products found.</td></tr>
                    )}
                </tbody>
            </Table>
            {hasMore && (
                <Button variant="primary" onClick={handleShowMore}>Show More</Button>
            )}

            {/* Image Preview Modal */}
            <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedImage && <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Preview" style={{ width: '100%' }} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowImageModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </div>
    );
}

export default Combolist;
