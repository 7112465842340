import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container, Card } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Sidenav from './LeftMenu';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';
import "../assets/css/addproduct.css"

function EditChildProduct() {
    const [product, setProduct] = useState({});
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [colors, setColors] = useState([]);
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState('');
    const [videoPreview, setVideoPreview] = useState('');
    const [stock, setStock] = useState({
        xs: "",
        s: "",
        m: "",
        l: "",
        xl: "",
        xxl: "",
    });

    const [packages, setPackages] = useState({
        packageWeight: '',
        packageheight: '',
        packagelength: '',
        packagewidth: '',
        productWeight: ''
    });
    const { id } = useParams(); // Get product ID from route
    const authorid = localStorage.getItem("authorid");
    const [fabricTypes, setFabricTypes] = useState([]);
    const [fits, setFits] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [patterns, setPatterns] = useState([]);
    const [hsnCodes, setHsnCodes] = useState([]);
    const [collars, setCollars] = useState([]);
    const [childId, setChildId] = useState("");
    const [sampleImages, setSampleImages] = useState({
        sample_image1: null,
        sample_image2: null,
        sample_image3: null,
        sample_image4: null,
    });
    const [stocks, setStocks] = useState({ stock: 0 });

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const token = localStorage.getItem("responsetoken");
                const url = `https://api.rollerfashions.com/api/Product/Get_duplicateproduct?Product_ID=${id}`;
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                if (response.data) {
                    console.log("API Response", response.data);
                    const fetchedChildId = response.data.id; // ID for the duplicate product
                    const fetchedProductId = response.data.product_ID; // ID for the main product, if returned

                    setChildId(fetchedChildId); // Set duplicate product ID
                    // Set product details
                    setProduct(response.data);
                    setStock({
                        xs: response.data.xs || "",
                        s: response.data.s || "",
                        m: response.data.m || "",
                        l: response.data.l || "",
                        xl: response.data.xl || "",
                        xxl: response.data.xxl || ""
                    });

                    setSampleImages({
                        sample_image1: response.data.sample_image1 || null,
                        sample_image2: response.data.sample_image2 || null,
                        sample_image3: response.data.sample_image3 || null,
                        sample_image4: response.data.sample_image4 || null,
                    });

                    fetchCategories();
                    fetchSubCategories(response.data.category_ID);
                    fetchColors();
                    fetchFits();
                    fetchFabricTypes();
                    fetchOccasions();
                    fetchPatterns();
                    fetchHsnCodes();
                    fetchCollars();
                    debugger;





                }
            } catch (error) {
                console.error("Error fetching product data:", error);
            }
        };

        fetchProduct();
    }, [id]);

    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem("responsetoken");
            const response = await axios.get('https://api.rollerfashions.com/api/Category/List_Category', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchSubCategories = async (categoryID) => {
        try {
            const token = localStorage.getItem("responsetoken");
            const response = await axios.get(`https://api.rollerfashions.com/api/SubCategory/List_SubCategory?Category_ID=${categoryID}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setSubCategories(response.data);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    const fetchColors = async () => {
        try {
            const token = localStorage.getItem("responsetoken");
            const response = await axios.get('https://api.rollerfashions.com/api/Color/List_Color', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setColors(response.data);
        } catch (error) {
            console.error("Error fetching colors:", error);
        }
    };
    const fetchFits = async () => {
        if (fits.length === 0) {
            try {
                const token = localStorage.getItem("responsetoken");
                const response = await axios.get('https://api.rollerfashions.com/api/Fit_type/List_fittype', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setFits(response.data);
            } catch (error) {
                toast.error("Failed to fetch fits");
            }
        }
    };

    const fetchFabricTypes = async () => {
        if (fabricTypes.length === 0) {
            try {
                const token = localStorage.getItem("responsetoken");
                const response = await axios.get('https://api.rollerfashions.com/api/Fabric_Type/List_fabrictype', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setFabricTypes(response.data);
            } catch (error) {
                toast.error("Failed to fetch fabric types");
            }
        }
    };
    const fetchHsnCodes = async () => {
        const token = localStorage.getItem("responsetoken");
        if (hsnCodes.length === 0) {
            try {
                const response = await axios.get('https://api.rollerfashions.com/api/Hsn/List_Hsn', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setHsnCodes(response.data);
            } catch (error) {
                toast.error("Failed to fetch HSN codes");
            }
        }
    };

    const fetchPatterns = async () => {
        const token = localStorage.getItem("responsetoken");
        if (patterns.length === 0) {
            try {
                const response = await axios.get('https://api.rollerfashions.com/api/Pattern/List_Pattern', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setPatterns(response.data);
            } catch (error) {
                toast.error("Failed to fetch patterns");
            }
        }
    };

    const fetchOccasions = async () => {
        if (occasions.length === 0) {
            try {
                const token = localStorage.getItem("responsetoken");
                const response = await axios.get('https://api.rollerfashions.com/api/Occasion/List_Occasion', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setOccasions(response.data);
            } catch (error) {
                toast.error("Failed to fetch occasions");
            }
        }
    };
    const fetchCollars = async () => {
        if (collars.length === 0) {
            try {
                const token = localStorage.getItem("responsetoken");
                const response = await axios.get('https://api.rollerfashions.com/api/Collar/List_Collar', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setCollars(response.data);
            } catch (error) {
                toast.error("Failed to fetch collars");
            }
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 102400) { // 100KB limit
                setImage(file);
                setImageName(file.name);
            } else {
                alert("Image size should be less than or equal to 100KB");
            }
        }
    };
    const handleSampleImageUpload = (e, key) => {
        const file = e.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file); // Create a URL for the uploaded file
            setSampleImages(prevState => ({
                ...prevState,
                [key]: fileURL // Store the file URL instead of the file object
            }));
        }
    };



    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const videoURL = URL.createObjectURL(file);
            setVideoPreview(videoURL);
            setProduct({ ...product, video: file });
        }
    };

    const handleStockChange = (size, value) => {
        setStock(prevStock => ({
            ...prevStock,
            [size]: value
        }));
    };
    debugger;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', childId); // Duplicate product ID
        formData.append('product_ID', product.product_ID); // 
        formData.append('name', product.name || '');
        formData.append('category_ID', product.category_ID || '');
        formData.append('subcategory_ID', product.subcategory_ID || '');
        formData.append('price', product.price || '');
        formData.append('selling_price', product.selling_price || '');
        formData.append('discount', product.discount || '');
        formData.append('description', product.description || '');
        formData.append('isfeature', product.isfeature ? 'true' : 'false');
        formData.append('isavailable', product.isavailable ? 'true' : 'false');
        formData.append('isreturnable', product.isreturnable ? 'true' : 'false');
        formData.append('color_ID', product.color_ID || '');
        formData.append('xs', stock.xs || '');
        formData.append('s', stock.s || '');
        formData.append('m', stock.m || '');
        formData.append('l', stock.l || '');
        formData.append('xl', stock.xl || '');
        formData.append('xxl', stock.xxl || '');
        formData.append('productWeight', product.productWeight || ''); // Ensure property name matches
        formData.append('packageWeight', product.packageWeight || ''); // Ensure property name matches
        formData.append('packageHeight', product.packageHeight || ''); // Ensure property name matches
        formData.append('packageLength', product.packageLength || ''); // Ensure property name matches
        formData.append('packageWidth', product.packageWidth || ''); // Ensure property name matches
        formData.append('manufaturedby', product.manufaturedby || '');
        formData.append('washcare', product.washcare || '');
        formData.append('Skq_number', product.skq_number || '');
        formData.append('fabrictype_ID', product.Fabrictype_ID || '');
        formData.append('fit_ID', product.fit || '');
        formData.append('occasion_ID', product.occasion || '');
        formData.append('pattern_ID', product.pattern || '');
        formData.append('hsn_ID', product.hsn || '');
        formData.append('collar_ID', product.collar || '');
        formData.append('isdark', product.isdark ? 'true' : 'false');
        formData.append('length', product.length || '');
        formData.append('sleeve_Length', product.sleeve_Length || '');
        formData.append('hemlength', product.hemlength || '');
        formData.append('Barcode_no', product.barcodeno || '');
        formData.append('Barcode_Image', product.barcodeimage || '');
        formData.append('IsCouponapplicable', product.iscouponapplicable ? 'true' : 'false');
        formData.append('isduplicate', product.isduplicate ? 'true' : 'false');
        formData.append('isCouponapplicable', product.isCouponapplicable ? 'true' : 'false');
        formData.append('Stock', stocks.stock);



        if (image) {
            formData.append('image', image);
        }

        if (product.video) {
            formData.append('video', product.video);
        }

        try {
            const token = localStorage.getItem("responsetoken");
            const authorid = localStorage.getItem("authorid");
            formData.append('Author_ID', authorid);
            debugger;
            const response = await axios.post('https://api.rollerfashions.com/api/Product/Edit_duplicateproduct', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });



            toast.success('Product updated successfully');
        } catch (error) {
            console.error("Error updating product:", error.response ? error.response.data : error.message);
            toast.error('Failed to update product');
        }
    };

    if (!product || Object.keys(product).length === 0) {
        return <div>Loading......</div>;
    }

    return (
        <Container fluid>
            <Row>
                <Col md={2}>
                    <Sidenav />
                </Col>
                <Col md={10}>
                    <div className="editchildproduct-container">
                        <h2>Edit Child Product</h2>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group controlId="itemName">
                                                <Form.Label>Item Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter item name"
                                                    value={product.name || ''}
                                                    onChange={(e) => setProduct({ ...product, name: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="category">
                                                <Form.Label>Category</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.category_ID || ''}
                                                    onChange={(e) => {
                                                        setProduct({ ...product, category_ID: e.target.value });
                                                        fetchSubCategories(e.target.value);
                                                    }}
                                                >
                                                    <option value="">Select category</option>
                                                    {categories.map((category) => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formTax">
                                                <Form.Label>Tax</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter tax percentage"
                                                    value={product.tax || ''}
                                                    onChange={(e) => setProduct({ ...product, tax: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="subcategory">
                                                <Form.Label>Subcategory</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.subcategory_ID || ''}
                                                    onChange={(e) => setProduct({ ...product, subcategory_ID: e.target.value })}
                                                >
                                                    <option value="">Select subcategory</option>
                                                    {subCategories.map((subcategory) => (
                                                        <option key={subcategory.id} value={subcategory.id}>
                                                            {subcategory.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="color">
                                                <Form.Label>Color</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.color_ID || ''}
                                                    onChange={(e) => setProduct({ ...product, color_ID: e.target.value })}
                                                >
                                                    <option value="">Select color</option>
                                                    {colors.map((color) => (
                                                        <option key={color.id} value={color.id}>
                                                            {color.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="fabrictype">
                                                <Form.Label >Fabric Type</Form.Label>
                                                <Form.Control as="select" value={product.fabricType} onChange={(e) => setProduct({ ...product, fabricType: e.target.value })}>
                                                    {fabricTypes.map((type) => (
                                                        <option key={type.id} value={type.id}>{type.fabricname}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="fit">
                                                <Form.Label >Fit</Form.Label>

                                                <Form.Control as="select" value={product.fit} onChange={(e) => setProduct({ ...product, fit: e.target.value })}>
                                                    {fits.map((fit) => (
                                                        <option key={fit.id} value={fit.id}>{fit.fitname}</option>
                                                    ))}
                                                </Form.Control>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="collar">
                                                <Form.Label >Collar Type</Form.Label>

                                                <Form.Control as="select" value={product.collar} onChange={(e) => setProduct({ ...product, collar: e.target.value })}>
                                                    {collars.map((collar) => (
                                                        <option key={collar.id} value={collar.id}>{collar.collar_Name}</option>
                                                    ))}
                                                </Form.Control>

                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="occasion">
                                                <Form.Label >Occasion</Form.Label>

                                                <Form.Control as="select" value={product.occasion} onChange={(e) => setProduct({ ...product, occasion: e.target.value })}>
                                                    {occasions.map((occasion) => (
                                                        <option key={occasion.id} value={occasion.id}>{occasion.occasion_type}</option>
                                                    ))}
                                                </Form.Control>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="pattern">
                                                <Form.Label >Pattern</Form.Label>

                                                <Form.Control as="select" value={product.pattern} onChange={(e) => setProduct({ ...product, pattern: e.target.value })}>
                                                    {patterns.map((pattern) => (
                                                        <option key={pattern.id} value={pattern.id}>{pattern.pattern_type}</option>
                                                    ))}
                                                </Form.Control>

                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="hsn">
                                                <Form.Label column >HSN Code</Form.Label>

                                                <Form.Control as="select" value={product.hsnCode} onChange={(e) => setProduct({ ...product, hsnCode: e.target.value })}>
                                                    {hsnCodes.map((hsnCode) => (
                                                        <option key={hsnCode.id} value={hsnCode.id}>{hsnCode.percentage}</option>
                                                    ))}
                                                </Form.Control>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="price">
                                                <Form.Label>Price</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter price"
                                                    value={product.price || ''}
                                                    onChange={(e) => setProduct({ ...product, price: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="sellingPrice">
                                                <Form.Label>Selling Price</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter selling price"
                                                    value={product.selling_price || ''}
                                                    onChange={(e) => setProduct({ ...product, selling_price: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {/* Add stock inputs for XS, S, M, L, XL, XXL */}
                                    <Row>

                                        <Col>
                                            <Form.Group controlId="stockXS">
                                                <Form.Label>Stock XS</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={stock.xs}
                                                    onChange={(e) => handleStockChange('xs', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="stockS">
                                                <Form.Label>Stock S</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={stock.s}
                                                    onChange={(e) => handleStockChange('s', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="stockM">
                                                <Form.Label>Stock M</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={stock.m}
                                                    onChange={(e) => handleStockChange('m', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="stockL">
                                                <Form.Label>Stock L</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={stock.l}
                                                    onChange={(e) => handleStockChange('l', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="stockXL">
                                                <Form.Label>Stock XL</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={stock.xl}
                                                    onChange={(e) => handleStockChange('xl', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="stockXXL">
                                                <Form.Label>Stock XXL</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={stock.xxl}
                                                    onChange={(e) => handleStockChange('xxl', e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {/* Image and Video Upload */}
                                    <Form.Group controlId="displayImage">
                                        <Form.Label>Display Image</Form.Label>
                                        <div>
                                            <img src={`${product.display_image}`} alt={product.name} className="product-list-images" />
                                            <Form.Control type="file" onChange={handleImageUpload} />
                                            {image && (
                                                <Card className="mt-3">
                                                    <Card.Img variant="top" src={URL.createObjectURL(image)} />
                                                    <Card.Body>
                                                        <Card.Title>{imageName}</Card.Title>
                                                        style={{ width: '60%' }}
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </div>
                                    </Form.Group>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Label>Sample Images</Form.Label>
                                            <Row>
                                                {Object.keys(sampleImages).map((key, index) => (
                                                    <Col xs={6} md={6} className="mb-3" key={index}>
                                                        <Card>
                                                            {sampleImages[key] ? (
                                                                <>
                                                                    <Card.Img
                                                                        variant="top"
                                                                        src={sampleImages[key]} // Use the URL for the image source
                                                                        alt={`Sample ${index + 1}`}
                                                                        className="img-thumbnail"
                                                                        style={{ width: '49%' }} // Adjust to fit your layout
                                                                    />
                                                                    <Card.Body>
                                                                        <Card.Title>{`Sample Image ${index + 1}`}</Card.Title>
                                                                    </Card.Body>
                                                                </>
                                                            ) : (
                                                                <Card.Body>
                                                                    <Card.Title>No Image Uploaded</Card.Title>
                                                                </Card.Body>
                                                            )}
                                                            {/* Upload Option */}
                                                            <Form.Control
                                                                type="file"
                                                                onChange={(e) => handleSampleImageUpload(e, key)}
                                                                className="mt-2"
                                                            />
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col md={3}>
                                            <Form.Group controlId="formIsDuplicate">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Is Duplicate"
                                                    checked={product.isduplicate || false}
                                                    onChange={(e) => setProduct({ ...product, isduplicate: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="formIsCouponApplicable">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Is Coupon Applicable"
                                                    checked={product.iscouponapplicable || false}
                                                    onChange={(e) => setProduct({ ...product, iscouponapplicable: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="formStockQuantity">
                                                <Form.Label>Stock Quantity</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter stock quantity for sizes"
                                                    value={stocks.stock || ''} // Access the stock property from stocks
                                                    onChange={(e) => setStocks({ ...stocks, stock: Number(e.target.value) })} // Update stocks state
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group controlId="formDiscount">
                                                <Form.Label>Discount</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter discount percentage"
                                                    value={product.discount || ''}
                                                    onChange={(e) => setProduct({ ...product, discount: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Row>
                                            <Col md={3}>
                                                <Form.Group controlId="formPackageWeight">
                                                    <Form.Label>Package Weight</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter package weight"
                                                        value={packages.packageWeight || ''}
                                                        onChange={(e) => setPackages({ ...packages, packageWeight: e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col md={3}>
                                                <Form.Group controlId="formPackageHeight">
                                                    <Form.Label>Package Height</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter package height"
                                                        value={packages.packageheight || ''}
                                                        onChange={(e) => setPackages({ ...packages, packageheight: e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col md={3}>
                                                <Form.Group controlId="formPackageLength">
                                                    <Form.Label>Package Length</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter package length"
                                                        value={packages.packagelength || ''}
                                                        onChange={(e) => setPackages({ ...packages, packagelength: e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col md={3}>
                                                <Form.Group controlId="formPackageWidth">
                                                    <Form.Label>Package Width</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter package width"
                                                        value={packages.packagewidth || ''}
                                                        onChange={(e) => setPackages({ ...packages, packagewidth: e.target.value })}
                                                    />
                                                </Form.Group>
                                            </Col>

                                        </Row>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="formManufacturedBy">
                                                <Form.Label>Manufactured By</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter manufacturer"
                                                    value={product.manufaturedby || ''}
                                                    onChange={(e) => setProduct({ ...product, manufaturedby: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formWashCare">
                                                <Form.Label>Wash Care</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter wash care instructions"
                                                    value={product.washcare || ''}
                                                    onChange={(e) => setProduct({ ...product, washcare: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>



                                        <Col md={6}>
                                            <Form.Group controlId="formGender">
                                                <Form.Label>Gender</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product.gender || ''}
                                                    onChange={(e) => setProduct({ ...product, gender: e.target.value })}
                                                >
                                                    <option>Select gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="unisex">Unisex</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="formSKU">
                                                <Form.Label>SKU Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter SKU number"
                                                    value={product.skq_number || ''}
                                                    onChange={(e) => setProduct({ ...product, skq_number: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId="formIsDuplicate">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Is Duplicate"
                                                    checked={product.isduplicate || false}
                                                    onChange={(e) => setProduct({ ...product, isduplicate: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId="formIsFeatured">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Is Featured"
                                                    checked={product.isfeature || false}
                                                    onChange={(e) => setProduct({ ...product, isfeature: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId="formIsReturnable">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Is Returnable"
                                                    checked={product.Isreturnable || false}
                                                    onChange={(e) => setProduct({ ...product, Isreturnable: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Form.Group controlId="video">
                                        <Form.Label>Video</Form.Label>
                                        <div>
                                            {product.video && (
                                                <video width="320" height="240" controls>
                                                    <source src={videoPreview || `${product.video}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )}
                                            <Form.Control
                                                type="file"
                                                accept="video/*"
                                                placeholder="Upload video"
                                                name="video"
                                                onChange={handleVideoUpload}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Update Product
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
}

export default EditChildProduct;
