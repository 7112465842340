import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Sidenav from './LeftMenu';

const EditCollar = () => {
    const { id } = useParams(); // Get the Collar ID from the URL
    const navigate = useNavigate();
    const [collarType, setCollarType] = useState('');
    const token = localStorage.getItem("responsetoken");
    const authorId = localStorage.getItem('authorid');

    useEffect(() => {
        const fetchCollarDetails = async () => {
            try {
                const response = await axios.get(`https://api.rollerfashions.com/api/Collar/Get_Collar?Collar_ID=${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.data) {
                    setCollarType(response.data.collar_Name); // Assuming collar_Name exists in the response
                }
            } catch (error) {
                console.error('Error fetching collar details:', error);
                toast.error('Failed to fetch collar details');
            }
        };

        fetchCollarDetails();
    }, [id, token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collarData = {
            id: id,
            collar_Name: collarType,
            author_ID: authorId,
        };

        try {
            const response = await axios.post(`https://api.rollerfashions.com/api/Collar/Edit_Collar`, collarData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.data.responseCode === 1) {
                toast.success('Collar updated successfully!');
                navigate('/AdminDashboard/collarlist'); // Redirect to the collar list after successful update
            } else {
                toast.error('Failed to update collar');
            }
        } catch (error) {
            console.error('Error updating collar:', error);
            toast.error('An error occurred while updating the collar');
        }
    };

    return (
        <Container fluid className="mt-5">
            <Row>
                <Col md={2}>
                    <Sidenav />
                </Col>
                <Col md={10}>
                    <h2 className='coupon-title'>Edit Collar</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formCollarType">
                            <Form.Label>Collar Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter collar name"
                                value={collarType}
                                onChange={(e) => setCollarType(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Update Collar
                        </Button>
                    </Form>
                </Col>
            </Row>

            <ToastContainer />
        </Container>
    );
};

export default EditCollar;
